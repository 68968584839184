import React, { useState, useEffect, useRef } from 'react';
import "./projects.css";

import Flicking from "@egjs/react-flicking";
import { Sync } from "@egjs/flicking-plugins";
import "@egjs/react-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/react-flicking/dist/flicking-inline.css";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea } from '@mui/material';

import ProjectCard from './project-card';


function filter_projects_by_name(projects) {
  let projects_to_remove = ["arca", "sma-reactive", "case-based-reasoning", "DynCo", "ros-devai", "TestROS", "khaled-abdrabo.github.io"];
  let finals = [];
  projects.forEach(project => {
    if (!projects_to_remove.includes(project.name)) finals.push(project);
  });

  return finals;
}

function Projects(props) {
  const flicking0 = useRef();
  const flicking1 = useRef();

  const [plugins, setPlugins] = useState([]);
  let projects = filter_projects_by_name(props.projects);

  useEffect(() => {
    setPlugins([new Sync({
      type: "index",
      synchronizedFlickingOptions: [
        {
          flicking: flicking0.current,
          isSlidable: true
        },
        {
          flicking: flicking1.current,
          isClickable: true,
          activeClass: "active"
        }
      ]
    })]);
  }, []);

  return (
    <div className="projects-container">
      <div className="selectors">
        <Flicking ref={flicking1}
          moveType="freeScroll"
          circular={true}
          bounce={30}>
          {projects.map((project) => {
            return (
              <div key={project.name} className="flicking-panel thumb has-background-primary">
                <Card className="selector">
                  <CardActionArea>
                    <CardContent>
                      <div>
                        <img className="project-icon"
                          src={require('../../../../../assets/projects/icons/' + project.name.toLowerCase() + '.png')}
                          alt={"⌛"} />
                      </div>
                      <div>
                        <h1 className="projet-small-title">{project.name}</h1>

                        {/* <Typography gutterBottom className="projet-small-title" component="div"> */}
                        {/* </Typography> */}
                      </div>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </div>
            )
          })}
        </Flicking>
      </div>
      <div className="visualizers">
        <Flicking ref={flicking0}
          bounce={30}
          align="center"
          circular={true}
          bound={false}
          plugins={plugins}>
          {projects.map((project, index) => {
            return (
              <div key={project.name} className="flicking-panel full has-background-primary current-viz">
                <Card className="visualizer">
                  <ProjectCard project={project} projectOrder={`${index+1}/${projects.length}`} backgroundImg={require('../../../../../assets/projects/icons/' + project.name.toLowerCase() + '.png')} />
                </Card>
              </div>
            )
          })}
        </Flicking>
      </div>
    </div>
  );
};

export default Projects;