import React from 'react';
import "./toggle.css";
import i18n from 'i18next'

function ToggleButton() {

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  }

  let newlang;
  if (i18n.language === 'en')
    newlang = 'fr';
  else
    newlang = 'en';

  return (
    <div className="toggle-container" onClick={() => changeLanguage(newlang)}>
      <div className={`dialog-button ${(i18n.language === 'en') ? "" : "disabled"}`}>
        {i18n.language === 'en' ? 'EN' : 'FR'}
      </div>
    </div>
  );
}

export default ToggleButton;