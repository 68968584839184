import { useEffect } from "react";
import "./visitTracker.css";

function useVisitTracker() {

    useEffect(() => {

        // ClusterMaps
        const script = document.createElement('script');
        script.type = "text/javascript";
        script.id = "clustrmaps";
        script.src = "//clustrmaps.com/map_v2.js?d=70BIj0py45IAXi8BY5Ok9vHDPmP-NzwOhq9GSsXcgOk";        
        document.body.appendChild(script);

        // Microsoft Clarity
        (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", "okpa7vhlsw");   

        // Google tag (gtag.js)
        // Note: this is complemented with the async script included in App.js
        window.dataLayer = window.dataLayer || [];
        function gtag(){window.dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', 'G-4B6PTLZ54P');

        return () => {
            document.body.removeChild(script);
        }
    })

    // useEffect(() => {
    //     const scriptPromise = new Promise((resolve, reject) => {
    //         const script = document.createElement('script');
    //         document.body.appendChild(script);
    //         script.onload = resolve;
    //         script.onerror = reject;
    //         script.async = true;
    //         script.type = "text/javascript";
    //         script.id = "clustrmaps";
    //         script.crossorigin = "anonymous";
    //         script.src = '//clustrmaps.com/map_v2.js?d=70BIj0py45IAXi8BY5Ok9vHDPmP-NzwOhq9GSsXcgOk';
    //       });
          
    //       scriptPromise.then(() => { console.log("after promise") });
          
    // })

}

export default useVisitTracker;