import React, { Component, memo } from 'react';

import './electro.css';

class ElectroSection extends Component {
  render() {
    return (
        <div>
        <h2>Electro Section</h2>  
        <p>Under construction, come back later</p>
    </div>
    );
  }
}

export default memo(ElectroSection);