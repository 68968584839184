import React from "react";
import { Link } from 'react-router-dom';
import './web.css';
import ToggleButton from '../../../../common/language-toggle';
import { withTranslation } from 'react-i18next';

function Web({ t }) {

    return <div className="web">
        <div className="web-option">
            <div className="initials">
                <Link to="/" state={{ fromResume: true }} replace={true} draggable="false">
                    <h2 className="initials-k">K<span className="initials-a">a</span></h2>
                </Link>
            </div>
        </div>
        <div className="web-option">
            <a href="#intro" draggable="false">
                {"Intro".toUpperCase()}
            </a>
        </div>
        <div className="web-option">
            <a href="#education" draggable="false">
                {t('education').toUpperCase()}
            </a>
        </div>
        <div className="bg-yellow-hightlight web-option">
            <a href="#projects" draggable="false">
                {t('projects').toUpperCase()}
            </a>
        </div>
        <div className="web-option">
            <a href="#experience" draggable="false">
                {t('experience').toUpperCase()} 
            </a>
        </div>
        <div className="web-option">
            <a href="#explore" draggable="false">
                {t('contact').toUpperCase()}
            </a>
        </div>
        <div className="language-toggle lng-toggle">
            <ToggleButton />
        </div>
    </div>;
}

export default withTranslation()(Web);