import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import './index.css';

function LoadingPage() {
  return (
  <div className="loading-page">
    {/* <h1 className="loading-prompt">Just a moment...</h1> */}
    <Box className="loading-prompt">
      <CircularProgress />
    </Box>
  </div>
  );
}

export default LoadingPage;