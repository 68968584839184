import React, { Component, memo } from 'react';

import './vfx.css';

class VFXSection extends Component {
  render() {
    return (
      <div>
        <h2>VFX Section</h2>
        <p>Under construction, come back later</p>
      </div>
    );
  }
}

export default memo(VFXSection);