export const AckTypefacesData=[
    {
        title: "Lemon Milk",
        author: "Muhammad Ariq Syauqi (Marsnev)",
        ressource_url: "https://www.marsnev.com/"
    },
    {
        title: "Colored Pencil",
        author: "LyonsType",
        ressource_url: "https://lyonstype4.wixsite.com/lyonstype-beta"
    },
    {
        title: "Anti Hero",
        author: "Khurasan",
        ressource_url: "https://www.creativefabrica.com/designer/khurasan/ref/53/"
    },
    {
        title: "KG Happy",
        author: "Kimberly Geswein",
        ressource_url: "https://kimberlygeswein.com/"
    },
    {
        title: "Spraypaint",
        author: "Typodermic Fonts",
        ressource_url: "https://typodermicfonts.com/"
    },
    {
        title: "Good Brush",
        author: "Sarah Robbaniyyah",
        ressource_url: "https://goodjavastudio.com/"
    },
]