import React from "react";
import { SocialData } from "../../data/social";
import "./social-contact.css";

function SocialContact({ textColor }) {
  const data = SocialData;

  return (
    <div className="social-contact">
        {data.map ((item) => {
          return (
            <a key={item.platform} href={item.link} target="_blank" rel="noopener noreferrer" title={item.platform}>
              <div className="social-icon-div">
                <h2 className={textColor}>{item.icon}</h2>
              </div>
            </a>
          );
        })} 
    </div>
  );
}

export default SocialContact;