export const AIMLStackData=[
    {
        "name": "basic-perceptrons",
        "topics": [
            "deep-learning",
            "pytorch",
            "mnist"
        ],
        "description": "This project offers a beginner's introduction to PyTorch, covering perceptrons and deep neural networks using the MNIST dataset. The goal is to recognize and classify handwritten digits from 0 to 9, providing a practical application for core machine learning concepts.",
        "html_url": "https://github.com/khaled-abdrabo/basic-perceptrons",
    },
    {
        "name": "gym-deep-rl",
        "topics": [
            "deep-neural-networks",
            "deep-reinforcement-learning",
            "openai-gym",
            "pytorch",
            "convolutional-neural-networks",
            "openai-gym-environments"
        ],
        "description": "This project focuses on balancing a pole in OpenAI's Gym Cartpole environment using various deep learning techniques. It aims to apply and compare different approaches to solve the balancing challenge effectively.",
        "html_url": "https://github.com/khaled-abdrabo/gym-deep-rl",
    },
    {
        "name": "qlearning",
        "topics": [
            "reinforcement-learning",
            "qlearning",
            "rl",
            "value-iteration",
            "gridworld-environment"
        ],
        "description": "This project focuses on implementing value iteration and Q-learning algorithms. We first test these agents in a Gridworld environment, then apply them to control a simulated robot (Crawler) and to the Pacman game. (Source: Berkeley's public projects and labs)",
        "html_url": "https://github.com/khaled-abdrabo/qlearning",
    },
    {
        "name": "sentence2emotion",
        "topics": [
            "nlp",
            "pytorch",
            "seaborn",
            "torchtext"
        ],
        "description": "This project utilizes natural language processing and Recurrent Neural Networks (RNNs) to classify sentences into one of six predefined emotions. It focuses on accurately matching text to emotional categories based on advanced NLP techniques.",
        "html_url": "https://github.com/khaled-abdrabo/sentence2emotion",
    },
    {
        "name": "smart-plant-pot",
        "topics": [
            "nodejs",
            "iot",
            "arduino",
            "reactjs"
        ],
        "description": "This project aims to use a hygrometer to measure the humidity level of a flower pot and notify users about their plant’s watering needs. It also includes features for identifying the plant and storing its humidity data in the app for ongoing monitoring.",
        "html_url": "https://github.com/khaled-abdrabo/smart-plant-pot",
    },
    {
        "name": "style-transfer",
        "topics": [
            "pytorch",
            "vgg",
            "convolutional-neural-networks",
            "torchvision"
        ],
        "description": "The goal of this project is to produce artistic images by merging the content from one image with the style of another. Using a pretrained VGG19 model, it minimizes both content and style loss achieving visually compelling results.",
        "html_url": "https://github.com/khaled-abdrabo/style-transfer",
    },
    {
        "name": "sudoku-solver",
        "topics": [
            "constraint-programming",
            "ortools",
            "cp-sat"
        ],
        "description": "This project offers a robust and flexible solution for solving Sudoku puzzles of different sizes (9x9, 16x16, and 36x36). It utilizes constraint programming techniques, specifically OR-tools' CP-SAT models, for effective puzzle solving.",
        "html_url": "https://github.com/khaled-abdrabo/sudoku-solver",
    },
];