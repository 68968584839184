import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MatrixRain from './header/matrix-rain';
import Projects from './content';

import { ProjectsData } from "../../../../data/projects";

import '../parallax-container.css';

class ProjectSection extends Component {

    constructor(props) {
        super(props);
        this.state = {
            projects: [],
            didLoad: false,
        };
    }

    componentDidMount() {
        // FETCH PROJECT DATA USING GITHUB'S PUBLIC REST API

        /* const url = 'https://api.github.com/users/khaled-abdrabo/repos';

        fetch(url, {
            method: 'GET',
            headers: {
             'Content-Type': 'application/json'
           },
            mode: 'cors',
            cache: 'default'
        }).then(response => response.json())
        //   .then(data => this.setState({ didLoad: true, projects: data }))
          .then(data => data.forEach((project) => {
            console.log(project.languages_url)
            let name = project.name;
            let topics = project.topics;
            let description = project.description;
            let html_url = project.html_url;

            fetch(project.languages_url, {
                method: 'GET',
                headers: {
                 'Content-Type': 'application/json'
               },
                mode: 'cors',
                cache: 'default'})
                .then(response => response.json())
                .then(languages => this.state.projects.push({name, topics, description, html_url, languages}))
          }))
            .then(this.setState({ didLoad: true }))
            .then(console.log(this.state.projects));  */

        this.setState({ projects: ProjectsData, didLoad: true }); 
    }
    
    render () {
        return (
        <div className="custom-parallax-container">
            <div className='custom-body' style={{ background: this.props.color }}>
                <div className="section-parallax section-1"></div>
                <div className="section-parallax section-2">
                  <MatrixRain title={this.props.t("projects")} />
                </div>
                <div className="section-parallax section-3">
                    {!this.state.didLoad ? 
                        <h2>Loading ... </h2> 
                    : <>{!this.state.projects[0] ? 
                        <h2>Failed to load projects :(</h2> 
                        : <Projects projects={this.state.projects}/> 
                        }</>
                    }
                </div>
            </div>
        </div>
        );
    }

};

export default withTranslation()(ProjectSection); 