export const SocialData=[
    {
        platform:"LinkedIn",
        icon: "j", // SocialShapes LinkedIn, k for circle frame
        link:"https://www.linkedin.com/in/khaledabdrabo",
    },
    {
        platform:"Github",
        icon: "X", // SocialShapes Github, Y for circle frame
        link:"https://github.com/khaled-abdrabo",
    },
];