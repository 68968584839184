// GAMEBOY HTML, CSS SOURCE Martin Gauer => https://martingauer.com/

import React, { Component, memo } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

import "react-awesome-button/dist/styles.css";
import "../../../assets/css/btn-styles.css";
import './tree.css';


const sleep = ms => new Promise(r => setTimeout(r, ms));

function TreeSection ()  {

  const location = useLocation()
  const fromResume = location.state?.fromResume === undefined ? false : true;

  const navigate = useNavigate()
  let landedOnPage = false;

  // Check the navigation type
  const navigationType = window.performance.getEntriesByType("navigation")[0]?.type;

  if (navigationType === 'reload' || !fromResume) {
    landedOnPage = true;
  } else if (navigationType === 'navigate' || navigationType === 'back_forward' || fromResume) {
    landedOnPage = false;
  } 

  return (
    <GameBoyNavigator navigate={navigate} landedOnPage={landedOnPage} />
  )
}

class GameBoyNavigator extends Component {

  constructor(props) {
    super(props)
    this.onKeyDown = this.onKeyDown.bind(this);
    this.state = {
      power: false,
      powerButtonStyle: "power-button",
      batteryLightStyle: "battery-light",
      screenStyle: "screen",
      screenContentIntroStyle: "hide-screen-content",
      screenContentStyle: "hide-screen-content",
      unavailableNoticeStyle: "screen-small-text",
      currentNavigatorIndex: 0,
    }
  }

  async turnOnGameboy() {
    if (!this.state.power) {
      this.setState({ powerButtonStyle: "power-button power-button-on" });
      
      await sleep(500);
      this.setState({ batteryLightStyle: "battery-light power-on" })
      
      await sleep(500);
      this.setState({ screenStyle: "screen screen-turn-on" })

      await sleep(1000);
      this.setState({ screenContentIntroStyle: "" })

      await sleep(2000);
      this.setState({ screenContentIntroStyle: "hide-screen-content", })

      await sleep(500);
      this.setState({ screenContentStyle: "" })
    }
  }

  async wakeGameboy() {
    if (!this.state.power) {
      this.setState({ 
        powerButtonStyle: "power-button power-button-on",
        batteryLightStyle: "battery-light power-on",
        screenStyle: "screen screen-turn-on",
        screenContentIntroStyle: "hide-screen-content", 
        screenContentStyle: "",
      });
    }
  }

  async turnOffGameboy() {
    if (this.state.power) {
      this.setState({ 
        powerButtonStyle: "power-button",
        batteryLightStyle: "battery-light",
        screenStyle: "screen",
        screenContentIntroStyle: "hide-screen-content",
        screenContentStyle: "hide-screen-content", 
        currentNavigatorIndex: 0,
      })
    }
  }

  async handlePower() {
    if (this.state.power) {
      this.turnOffGameboy().then(() => 
        this.setState({ power: false })
      )
    } else {
      this.turnOnGameboy().then(() =>
        this.setState({ power: true })
      )
    }
  }

  onKeyDown = (e) => {
    e.preventDefault();
    if (this.state.power) {
      // if (e.key === "Escape") {
      //     this.handlePower()
      // }
      if (e.key === "a" || e.key === "A" || e.key === "b" || e.key === "B") {
        this.handlePageSelect()
      }
      if (e.key === "ArrowUp" || e.key === "ArrowLeft") {
        this.handleUpNavigation()
      }
      if (e.key === "ArrowDown" || e.key === "ArrowRight") {
        this.handleDownNavigation()
      }
      if (e.key === "Enter" || e.key === " ") {
        this.handlePageSelect()
      }
    }
  }

  async componentDidMount() {
    document.addEventListener("keyup", this.onKeyDown, false);

    // If landed on page turn GameBoy, if not just wake device up
    if (this.props.landedOnPage) {
      await sleep(1000);
      this.turnOnGameboy().then(() => 
        this.setState({ power: true })
      )
    } else {
      this.wakeGameboy().then(() => 
        this.setState({ power: true }))
    }
  }

  componentWillUnmount(){
    document.removeEventListener("keyup", this.onKeyDown, false);
  }

  handleDownNavigation = () => {
    if (this.state.power) {
      this.setState({ unavailableNoticeStyle: "screen-small-text" })
      this.setState({ currentNavigatorIndex: this.state.currentNavigatorIndex+1 })

      if (this.state.currentNavigatorIndex >= 4) 
        this.setState({ currentNavigatorIndex: 0 })
    }
  }

  handleUpNavigation = () => {
    if (this.state.power) {
      this.setState({ unavailableNoticeStyle: "screen-small-text" })
      this.setState({ currentNavigatorIndex: (this.state.currentNavigatorIndex-1) })

      if (this.state.currentNavigatorIndex <= 0)
        this.setState({ currentNavigatorIndex: 4 })
    }
  }

  async handleUnavailablePageSelect() {
    this.setState({ unavailableNoticeStyle: "screen-small-text text-blink" })
    await sleep(2000);
    this.setState({ unavailableNoticeStyle: "screen-small-text" })
  }

  handlePageSelect = () => {
    if (this.state.power) {
      if (this.state.currentNavigatorIndex === 0) {
        window.scrollTo(0, 0)
        this.props.navigate("/resume#intro")
      }
      if (this.state.currentNavigatorIndex === 1) {
        // this.props.navigate("/ai-ml")
        this.handleUnavailablePageSelect()
      }
      if (this.state.currentNavigatorIndex === 2) {
        // this.props.navigate("/demos")
        this.handleUnavailablePageSelect()
      }
      if (this.state.currentNavigatorIndex === 3) {
        // this.props.navigate("/art")
        this.handleUnavailablePageSelect()
      }
      if (this.state.currentNavigatorIndex === 4) {
        // this.props.navigate("/words-n-ideas")
        this.handleUnavailablePageSelect()
      }
    }
  }

  render() {
    return (
      <div className="tree" onKeyDown={this.onKeyDown}>
        <div className="tree-container">
          {/* <div><Link to="/vfx"><AwesomeButton style={{ width: '100%', margin: '20px' }} type="secondary" disabled={true}>Coming soon.. </AwesomeButton></Link></div> // VFX 
          <div><Link to="/electro"><AwesomeButton style={{ width: '100%', margin: '20px' }} type="secondary" disabled={true}>Coming soon.. </AwesomeButton></Link></div> // Electro */}

          <div className="gameboy">
            <div className="front-plate">
              <div className="front-plate-head">
                <div className="vertical-stripe"></div>
                <div className="vertical-stripe"></div>
                <div className="vertical-stripe"></div>
          
                <div className="vertical-gouge vertical-gouge-1"></div>
                <div className="vertical-gouge vertical-gouge-2"></div>
          
                <div className="on-off">
                  <div className="spike spike-left"><div></div></div>
                  <div className="spike spike-right"><div></div></div>
                  <span>
                    OFF
                    <i></i>
                    ON
                  </span>
                </div>
              </div>
          
              <div className="screen-container">
                <div className="screen-headline">
                  <span>DOT MATRIX WITH STEREO SOUND</span>
                </div>
          
                <div className={this.state.batteryLightStyle}>
                  <span>BATTERY</span>
                </div>
          
                <div className={this.state.screenStyle}>
                  <div className={this.state.screenContentIntroStyle} >
                    {this.state.screenContentStyle === "hide-screen-content" ?
                      <img className="screen-powerup-intro" draggable="false"
                         alt="introduction_logo" src={require("../.../../../../assets/intro/ka_logo_intro.gif")}/>
                      : <></>
                    }
                  </div>
                  <div className={this.state.screenContentStyle}>
                    {this.state.screenStyle === "screen screen-turn-on" ?
                      <>                  
                      {/* <PageNavigator /> */}
                      <br /><br />
                      <p className="screen-content">&nbsp;<b>Navigate to: </b></p>
                      <br />
                      {this.state.currentNavigatorIndex === 0 ?
                        <p className="screen-content">&nbsp;~Resume </p>
                        : <p className="screen-disabled-text">&nbsp;&nbsp; Resume </p>
                      }
                      <br />
                      {this.state.currentNavigatorIndex === 1 ?
                        <>
                          <p className="screen-content">&nbsp;~AI - ML </p>
                          <p className={this.state.unavailableNoticeStyle}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                        </>
                      : <>
                          <p className="screen-disabled-text">&nbsp;&nbsp;&nbsp;AI - ML </p>
                          <p className="screen-small-text screen-disabled-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                        </>
                      }
                      <br />
                      {this.state.currentNavigatorIndex === 2 ?
                        <>
                          <p className="screen-content">&nbsp;~Demos </p>
                          <p className={this.state.unavailableNoticeStyle}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                        </>
                      : <>
                          <p className="screen-disabled-text">&nbsp;&nbsp;&nbsp;Demos </p>
                          <p className="screen-small-text screen-disabled-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                        </>
                      }
                      <br />
                      {this.state.currentNavigatorIndex === 3 ?
                        <>
                          <p className="screen-content">&nbsp;~Digital Art </p>
                          <p className={this.state.unavailableNoticeStyle}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                        </>
                      : <>
                          <p className="screen-disabled-text">&nbsp;&nbsp;&nbsp;Digital Art </p>
                          <p className="screen-small-text screen-disabled-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                        </>
                      }
                      <br />
                      {this.state.currentNavigatorIndex === 4 ?
                          <>
                            <p className="screen-content">&nbsp;~Blog </p>
                            <p className={this.state.unavailableNoticeStyle}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                          </>
                        : <>
                            <p className="screen-disabled-text">&nbsp;&nbsp;&nbsp;Blog </p>
                            <p className="screen-small-text screen-disabled-text">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coming soon </p>
                          </>
                      }
                      </>
                    : <></>
                    }
                  </div>
                </div>
              </div>
          
              <div className="logo"></div>
          
              <div id="controller">
          
                <div className="buttons-a-b">
                  <div className="button-b" onClick={() => this.handlePageSelect()}></div>
          
                  <div className="button-a" onClick={() => this.handlePageSelect()}></div> 
                </div>
                
                <div className="start" onClick={() => this.handlePageSelect()}><div></div></div>
          
                <div className="select" onClick={() => this.handlePageSelect()}><div></div></div>
          
                <div className="cross-container">
                  <div className="spike"><div></div></div>
                  <div className="spike"><div></div></div>
                  <div className="spike"><div></div></div>
                  <div className="spike"><div></div></div>
          
                  <div className="cross">
                    <div className="top-down">
                      <div className="button-top" onClick={() => this.handleUpNavigation()}>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                      </div>
                      <div className="button-bottom" onClick={() => this.handleDownNavigation()}>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                      </div>
                    </div>
          
                    <div className="left-right">
                      <div className="button-left" onClick={() => this.handleUpNavigation()}>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                      </div>
                      <div className="button-right" onClick={() => this.handleDownNavigation()}>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                        <div className="button-stripe"></div>
                      </div>
                    </div>
                    <div className="cross-middle-bumb"></div>
                  </div>
                </div>
              </div>
          
              <div className="speaker">
                <div><div className="speaker-inner-shadow"></div></div>
                <div><div className="speaker-inner-shadow"></div></div>
                <div><div className="speaker-inner-shadow"></div></div>
                <div><div className="speaker-inner-shadow"></div></div>
                <div><div className="speaker-inner-shadow"></div></div>
                <div><div className="speaker-inner-shadow"></div></div>
              </div>
          
              <div className="phones">
                <div className="vertical-stripe"></div>
                <div className="vertical-stripe"></div>
                <div className="vertical-stripe"></div> 
                <i></i>
                <span>PHONES</span>
              </div>
          
            </div>
          
            {/* disabled={!this.state.power} onClick={() => this.handlePower()} */}
            <div className={this.state.powerButtonStyle} disabled={!this.state.power} onClick={() => this.handlePower()}><div></div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default memo(TreeSection);