import React, { Component } from 'react';
import { ReactComponent as MySvg } from './paper-plane.svg';

import './paper-plane.css';


class MySvgComponent extends Component {
  constructor(props) {
    super(props)
    this.canvasRef = React.createRef();
    this.canvas = this.canvasRef.current;
    this.ctx = null;
    // this.width = 0;
    // this.height = 0;
    this.frame = null;
    this.time = 0;
    this.destination = 1;
    this.diameter = 15;
  }

  componentDidMount() {
    // this.ctx = this.canvasRef.current.getContext('2d');
    // this.resize();

    window.addEventListener('resize', this.resize);
    // window.addEventListener('mousemove', this.handleMouseMove);
    // window.addEventListener('touchmove', this.handleMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    // window.removeEventListener('mousemove', this.handleMouseMove);
    // window.removeEventListener('touchmove', this.handleMouseMove);
    // cancelAnimationFrame(this.frame);
  }

  resize = () => {
    // this.canvas.width = window.innerWidth;  // this.width = 
    // this.canvas.height = window.innerHeight;  // this.height = 
    // cancelAnimationFrame(this.frame);
    // this.width = this.canvasRef.current.width = window.innerWidth;
    // this.height = this.canvasRef.current.height = window.innerHeight * 0.07;
    // this.halfWidth = this.width / 2;
    // this.ctx.globalCompositeOperation = 'lighter';
    // this.loop();
  };

  loop = () => {
    // this.frame = requestAnimationFrame(this.loop);
    // this.time += (this.destination - this.time) * 0.1;
    // this.ctx.clearRect(0, 0, this.width, this.height);
    // for (let i = 0; i < this.height; i += this.diameter) {
    //   for (let j = 0; j < this.halfWidth; j += this.diameter) {
    //     for (let channel = 0; channel < 3; channel++) {
    //       if (channel === 0) this.ctx.fillStyle = '#FF0000';
    //       if (channel === 1) this.ctx.fillStyle = '#00FF00';
    //       if (channel === 2) this.ctx.fillStyle = '#0000FF';

    //       const index = i * this.width + j;
    //       this.ctx.globalAlpha = Math.tan(index * index - this.time);

    //       this.ctx.fillRect(
    //         Math.tan(
    //           i * j - Math.sin(index + channel / 100) + this.time
    //         ) *
    //         j +
    //         this.halfWidth -
    //         this.diameter / 2,
    //         i,
    //         ((Math.tan(index + i / j + this.time + channel / 100) / 2) *
    //           this.diameter) /
    //         2,
    //         (Math.tan(index * index - this.time) * this.diameter) / 2
    //       );
    //     }
    //   }
    // }
  };

  handleMouseMove = (event) => {
    event.preventDefault();
    // const e = !!event.touches ? event.touches[0] : event;
    // this.destination = e.pageX / this.width;
  };

  render() {
    const canvasStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      background: '#FFF',
    };

    const titleStyle = {
      position: 'fixed',
      color: '#FFF',
      fontFamily: "Antihero",
      fontSize: '3.3vmax',
      backgroundColor: '#FFF',
      textAlign: 'center',
      zIndex: 99,
    };

    return (
      <div style={canvasStyle}>
        {/* <canvas ref={this.canvasRef} /> */}
        <MySvg />
        <h1 style={titleStyle}>{this.props.title}</h1>
      </div>
    );
  }
}

export default MySvgComponent;
