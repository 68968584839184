import React, { memo } from 'react';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import SocialContact from '../../../../common/social-contact';
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import "../../../../../assets/css/btn-styles.css";

import './explore.css';


function ExploreSection() {

    const { t } = useTranslation();

    return (
        <div className="explore-container">
            <Link to="/contact">
                <AwesomeButton type="primary" className="explore-btn">&nbsp;{t('contact')}&nbsp;</AwesomeButton>
            </Link>
            <Link to="/ack">
                <AwesomeButton type="primary" className="explore-btn">&nbsp;{t('acknowledgments')}&nbsp;</AwesomeButton>
            </Link>
            <a target="_blank" rel="noreferrer" href="https://github.com/khaled-abdrabo/personal.web" >
                <AwesomeButton type="primary" className="explore-btn">&nbsp;{t('source-code')}&nbsp;</AwesomeButton>
            </a>
            <Link to="/">
                <AwesomeButton type="primary" className="explore-btn">&nbsp;{t('home')}&nbsp;</AwesomeButton>
            </Link>

            <div style={{ padding: '5vh' }}>
                <SocialContact textColor={"social-icon-explore-section"}/>
            </div>
        </div >
    );
}

export default memo(ExploreSection);