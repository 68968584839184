import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { gsap } from 'gsap';
import Peep from "./peep.jsx";
import { AckStylingData } from '../../../data/ack-styling.js';
import { AckTypefacesData } from '../../../data/ack-typefaces.js';
import { withTranslation } from 'react-i18next';

import "./../ack.css";

const config = {
  src: require("../../../../assets/ack/crowd.png"),
  rows: 15,
  cols: 7
};

class PeepAnimation extends Component {
  constructor(props) {
    super(props);
    this.stage = {
      width: 0,
      height: 0
    };
    this.allPeeps = [];
    this.availablePeeps = [];
    this.crowd = [];
    this.walks = [ this.normalWalk ];
    this.canvasRef = React.createRef();
    this.ctx = null;
    this.fixedOffsetY = 150;
  }

  componentDidMount() {
    this.canvas = this.canvasRef.current;
    this.img = document.createElement('img');
    this.img.onload = this.init;
    this.img.src = config.src;
    this.resize();

    window.addEventListener('resize', this.resize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    gsap.ticker.remove(this.renderCanvas);
  }

  // UTILS

  randomRange = (min, max) => min + Math.random() * (max - min);

  randomIndex = (array) => this.randomRange(0, array.length) | 0;

  removeFromArray = (array, i) => array.splice(i, 1)[0];

  removeItemFromArray = (array, item) => this.removeFromArray(array, array.indexOf(item));

  removeRandomFromArray = (array) => this.removeFromArray(array, this.randomIndex(array));

  getRandomFromArray = (array) => (
    array[this.randomIndex(array) | 0]
  )

  init = () => {
    this.createPeeps();
    this.resize();

    gsap.ticker.add(this.renderCanvas);
  };

  createPeeps = () => {
    const { rows, cols } = config;
    const { naturalWidth: width, naturalHeight: height } = this.img;
    const total = rows * cols;
    const rectWidth = width / rows;
    const rectHeight = height / cols;

    for (let i = 0; i < total; i++) {
      this.allPeeps.push(new Peep ({
        image: this.img,
        rect: [
          (i % rows) * rectWidth,
          (i / rows | 0) * rectHeight,
          rectWidth,
          rectHeight
        ]
      }));
    }
  };

  resize = () => {
    let windowWidth = window.innerWidth;
    let windowHeight = window.innerHeight;
    
    this.stage.width = windowWidth;
    this.stage.height = windowHeight;
    
    this.canvas.width = windowWidth;
    this.canvas.height = windowHeight - 1;

    // Change Y coordinates based off device aspect ratio
    this.fixedOffsetY = 150;
    // Portrait mode
    if (windowHeight < windowWidth) {
      if (windowWidth <= 1024 && windowWidth >= 720) {
        this.fixedOffsetY = 100;
      }
    }
    // Landscape mode 
    else {

    }

    this.crowd.forEach((peep) => {
      peep.walk.kill();
    });

    this.crowd.length = 0;
    this.availablePeeps.length = 0;
    this.availablePeeps.push(...this.allPeeps);

    this.initCrowd();
  };

  initCrowd = () => {
    while (this.availablePeeps.length) {
      this.addPeepToCrowd().walk.progress(Math.random());
    }
  };

  addPeepToCrowd = () => {
    const peep = this.removeRandomFromArray(this.availablePeeps);
    const walk = this.getRandomFromArray(this.walks)({
      peep,
      props: this.resetPeep({
        peep,
        stage: this.stage
      })
    }).eventCallback('onComplete', () => {
      this.removePeepFromCrowd(peep);
      this.addPeepToCrowd();
    });

    peep.walk = walk;

    this.crowd.push(peep);
    this.crowd.sort((a, b) => a.anchorY - b.anchorY);

    return peep;
  };

  removePeepFromCrowd = (peep) => {
    this.removeItemFromArray(this.crowd, peep);
    this.availablePeeps.push(peep);
  };

  resetPeep = ({ stage, peep }) => {
    const direction = Math.random() > 0.5 ? 1 : -1;
    const offsetY = 150 - 150 * gsap.parseEase('power2.in')(Math.random());
    const startY = stage.height - peep.height + offsetY;
    let startX;
    let endX;

    if (direction === 1) {
      startX = -peep.width;
      endX = stage.width;
      peep.scaleX = 1;
    } else {
      startX = stage.width + peep.width + offsetY;
      endX = 0;
      peep.scaleX = -1;
    }

    peep.x = startX;
    peep.y = startY;
    peep.anchorY = startY;

    return {
      startX,
      startY,
      endX
    };
  };

  normalWalk = ({ peep, props }) => {
    // const { startX, startY, endX } = props;
    const { startY, endX } = props;
    const xDuration = 20;
    const yDuration = 0.25;

    const tl = gsap.timeline();
    tl.timeScale(this.randomRange(0.5, 1.5));
    tl.to(peep, {
      duration: xDuration,
      x: endX,
      ease: 'none'
    }, 0);
    tl.to(peep, {
      duration: yDuration,
      repeat: xDuration / yDuration,
      yoyo: true,
      y: startY - 10
    }, 0);

    return tl;
  };

  renderCanvas = () => {
    this.canvas.width = this.stage.width;
    this.ctx = this.canvasRef.current.getContext('2d');
    this.ctx.save();
    // this.ctx.scale(devicePixelRatio, devicePixelRatio);

    this.crowd.forEach((peep) => {
      peep.render(this.ctx);
    });

    this.ctx.restore();
  };

  render() {
    const canvasStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'baseline',
      width: '100%',
      height: '100%',
      background: '#f1f1f1',
    };

    const titleStyle = {
      color: '#000',
      fontFamily: "KGHAPPY",
      fontSize: '2.5vmax',
      textAlign: 'center',
      paddingTop: '4vmax',
      paddingBottom: "5vmax",
      zIndex: 1,
    };

    const contentStyle = {
      position: 'fixed',
      color: '#000',
      width: '100%',
      fontFamily: 'LemonMilkMedium',
      fontSize: '1vmax',
      // backgroundColor: '#FFF',
      textAlign: 'center',
      margin: '0',
      zIndex: 1 
    };

    const navigatorStyleDesktop = {
      position: 'absolute',
      top: '2vmax',
      left: '0.3vmax',
      zIndex: 1,
      color: '#000'
    }

    const navigatorStyleMobile = {
      position: 'absolute',
      top: '4.5vmax',
      left: '0.3vmax',
      zIndex: 1,
      color: '#000'
    }

    return (<>
      <div style={canvasStyle}>
        <canvas ref={this.canvasRef} />   
        <div style={contentStyle}> 
          <h1 style={titleStyle}>{this.props.t('acknowledgments')}</h1>
          <div className="ack-content-block">
            <div className="ack-styling-block">
              <h2 className="ack-subtitle">{this.props.t('styling')}</h2>
              <hr />
              {AckStylingData.map((item) => {
                  return(<>
                  <p key={item.ressource_url}>
                    <a href={item.ressource_url} className="ack-url" target="_blank" rel="noreferrer" draggable={false}>{item.title}</a>
                    &nbsp;/&nbsp;
                    <span className="ack-author">{item.author}</span>
                  </p>
                  </>)
                
              })}
            </div>
            <div className="ack-typefaces-block">
              <h2 className="ack-subtitle">{this.props.t('typefaces')}</h2>
              <hr />
              {AckTypefacesData.map((item) => {
                  return(<>
                  <p key={item.ressource_url}>
                    <a href={item.ressource_url} className="ack-url" target="_blank" rel="noreferrer" draggable={false}>{item.title}</a>
                    &nbsp;/&nbsp;
                    <span className="ack-author">{item.author}</span>
                  </p>
                  </>)
                
              })}
            </div>
            <Link to="/resume#explore" style={navigatorStyleDesktop} className="ack-home-navigator-desktop" draggable={false}>← {this.props.t('navigate_back')}</Link>
            <Link to="/resume#explore" style={navigatorStyleMobile} className="ack-home-navigator-mobile" draggable={false}>←</Link>
          </div> 
        </div>
      </div>
    </>);

  }
}

export default withTranslation()(PeepAnimation);