import { Component } from 'react';
import { Link } from "react-router-dom";
import { withTranslation } from 'react-i18next';
import MySvgComponent from './../contact/header/svg-component';
import ExploreContent from './content';
import ContactSection from '../contact/content';
import Footer from '../../../../fullpage/Footer';

import '../parallax-container.css';
import './explore.css';

class ExploreSection extends Component {
    render() {
        return (
            <>
                <div className="desktop-only">
                    <div className="custom-parallax-container" style={{ background: this.props.color }}>
                        <div className='custom-body'>
                            <div className="section-parallax section-1"></div>
                            <div className="section-parallax section-2">
                                <MySvgComponent title={this.props.t("contact-title").toUpperCase()} />
                            </div>
                            <div className="section-parallax section-3" style={{ paddingTop: '7vh' }}>
                                <ContactSection></ContactSection>
                            </div>
                        </div>
                    </div>
                    <Footer>
                        <Link className="explore-link" draggable="false" to="/ack">
                            {this.props.t('acknowledgments')}
                        </Link>
                        <a className="explore-link" draggable="false" target="_blank" rel="noreferrer" href="https://github.com/khaled-abdrabo/personal.web" >{this.props.t('source-code')}</a>
                        {/* <span>{this.props.t('created-by')} Khaled Abdrabo © 2023</span> */}
                    </Footer>
                </div>

                <div className="portable-only">
                    <div className="custom-parallax-container" style={{ background: "#000" }}>
                        <div className='custom-body'>
                            <div className="section-parallax section-1"></div>
                            <div className="section-parallax section-2">
                                <h1 className="explore-title"><hr />[¤_{this.props.t("explore-title").toUpperCase()}_¤]<hr /></h1>
                            </div>
                            <div className="section-parallax section-3">
                                <ExploreContent></ExploreContent>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }

};

export default withTranslation()(ExploreSection); 
