import React, { useRef, useState } from 'react';
import { Link } from "react-router-dom";
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next';
import { AwesomeButton } from "react-awesome-button";
import "react-awesome-button/dist/styles.css";
import "../../../../../assets/css/btn-styles.css";
import "./contact.css";

function getStatusComp(t, status) {
  if (status === "OK") 
    return <div className="form-status success">{t("message-sent")}</div>
  else if (status === "ER") 
    return <div className="form-status error">{t("failed-to-send")}</div>  
  return;
}

function ContactContent () {
  const { t } = useTranslation();

  const form = useRef();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formStatus, setFormStatus] = useState("");

  // const onNameInput = (e) => setName(e.target.value);
  // const onEmailInput = (e) => setEmail(e.target.value);
  // const onMessageInput = (e) => setMessage(e.target.value);

  const onClear = () => {
    setName("");
    setEmail("");
    setMessage("");
  }

  const sendEmail = (e) => {
    e.preventDefault(); 

    emailjs.sendForm('service_ts55sw9', 'template_xl27soc', form.current, 'iSJgILsRm_YvNFffF')
      .then((result) => {
          console.log(result)
          if (result.status === 200) {
            onClear();
            setFormStatus("OK");
          }
      }, (error) => {
          console.log(error)
          setFormStatus("ER");
      });
  };

  return (
    <div className="contact">
      <div className="contact-container">
        <form ref={form} onSubmit={sendEmail}>
            {getStatusComp(t, formStatus)}
            <div><input type="text"  name="user_name" className="contact-input" placeholder={t('name')} onChange={e => setName(e.target.value)} value={name} required /></div>
            <div><input type="email" name="user_email" className="contact-input" placeholder={t('email')} onChange={e => setEmail(e.target.value)} value={email} required /></div>
            <div><textarea name="message" className="contact-input comments-textarea" placeholder={t('comments')} rows="10" onChange={e => setMessage(e.target.value)} value={message} required /></div>
            <AwesomeButton
                style={{ width: '70%', margin: '20px' }}
                type="secondary"
            >
                {t('submit')}{ /** (t('submit') === formStatus) ? t('submit') : formStatus **/ }
            </AwesomeButton>
        </form> 
      </div>

      <Link to="/resume#explore" className="home-navigator" draggable={false}>← {t('navigate_to_home')}</Link>
    </div>
  );  
}

export default ContactContent;

