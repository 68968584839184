import React, { Component, memo } from 'react';

import './art.css';

class ArtSection extends Component {
  render() {
    return (
      <div>
        <h2>Art Section</h2>
        <p>Under construction, come back later</p>
      </div>
    );
  }
}

export default memo(ArtSection);