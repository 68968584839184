export const AckStylingData=[
    {
        title: "CSS Game Boy",
        author: "Martin Gauer",
        ressource_url: "https://github.com/attackemartin/css-gameboy"
    },
    {
        title: "Matrix Rain",
        author: "Clive Cooper",
        ressource_url: "https://codepen.io/yaclive/pen/EayLYO"
    },
    {
        title: "Mechanical Grass",
        author: "Tim Holman",
        ressource_url: "https://codepen.io/tholman/pen/DvYNNV"
    },
    {
        title: "Crowd Simulator",
        author: "Szenia Zadvornykh",
        ressource_url: "https://codepen.io/zadvorsky/pen/xxwbBQV"
    },
    {
        title: "Cyberpunk-Style Glitch",
        author: "Matt Gross",
        ressource_url: "https://codepen.io/mattgrosswork/pen/VwprebG"
    },
    {
        title: "Paper Plane",
        author: "Jon Q",
        ressource_url: "https://codepen.io/_ItsJonQ/pen/jOVwoJ"
    },
]