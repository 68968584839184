import React, { Component } from 'react';

import Matrix2D from './matrix-2d';

class MechanicalWeb extends Component {
  constructor(props) {
    super(props);
    this.state = {
        worms: [],
    };
    this.canvasRef = React.createRef();
    this.canvas = null;
    // this.ctx = null;
    this.width = null;
    this.height = null;
    this.mouse = { x: 0, y: window.innerHeight };
    this.interval = null;
    this.MAX_NUM = 100;
    this.N = 80;
    this.px = 500;
    this.py = 500;
  }

  resize = () => {
    this.width = this.canvas.width = window.innerWidth;
    this.height = this.canvas.height = window.innerHeight * 0.15;
  }

  componentDidMount() {
    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext('2d');
    this.width = window.innerWidth;
    this.height = window.innerHeight * 0.15;
    this.canvas.width = this.width;
    this.canvas.height = this.height;

    window.addEventListener('resize', this.resize);
    this.canvas.addEventListener('mousemove', this.mouseMove, false);
    this.canvas.addEventListener('click', this.mouseDown, false);

    this.interval = setInterval(this.draw, 20);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    clearInterval(this.interval);
  }

  draw = () => {
    const len = this.state.worms.length;
    for (let i = 0; i < len; i++) 
    {
        let worm = this.state.worms[i];
        if (worm !== null && worm !== undefined) 
        {
            if (worm.count < this.N) {
                this.drawWorm(worm, i);
                worm.count++;
            } else {
                this.state.worms.splice(i, 1);
                i--;
            }
        }
    }

    this.check();
  };

  drawWorm = (worm, index) => {
    if (Math.random() > 0.9) {
      worm.tmt.rotate(-worm.r * 2);
      worm.r *= -1;
    }

    worm.vmt.prependMatrix(worm.tmt);

    let cc1x = -worm.w * worm.vmt.c + worm.vmt.tx;
    let cc1y = -worm.w * worm.vmt.d + worm.vmt.ty;

    let pp1x = (worm.c1x + cc1x) / 2;
    let pp1y = (worm.c1y + cc1y) / 2;

    let cc2x = worm.w * worm.vmt.c + worm.vmt.tx;
    let cc2y = worm.w * worm.vmt.d + worm.vmt.ty;

    let pp2x = (worm.c2x + cc2x) / 2;
    let pp2y = (worm.c2y + cc2y) / 2;

    this.ctx.fillStyle = '#c10031';
    this.ctx.strokeStyle = '#c10031';
    this.ctx.beginPath();

    this.ctx.moveTo(worm.p1x, worm.p1y);
    this.ctx.quadraticCurveTo(worm.c1x, worm.c1y, pp1x, pp1y);

    this.ctx.lineTo(pp2x, pp2y);

    this.ctx.quadraticCurveTo(worm.c2x, worm.c2y, worm.p2x, worm.p2y);

    this.ctx.closePath();
    this.ctx.fill();

    worm.c1x = cc1x;
    worm.c1y = cc1y;
    worm.p1x = pp1x;
    worm.p1y = pp1y;
    worm.c2x = cc2x;
    worm.c2y = cc2y;
    worm.p2x = pp2x;
    worm.p2y = pp2y;

    //this.worms[index] = worm;
  };

  check = () => {
    let x0 = this.mouse.x;
    let y0 = this.mouse.y;

    let vx = x0 - this.px;
    let vy = y0 - this.py;

    let len = Math.min(this.magnitude(vx, vy), 50) + 8;

    if (len < 10) {
      return;
    }

    let matrix = new Matrix2D();
    // matrix.context = this.ctx;
    matrix.rotate(-Math.atan2(vx, vy));

    matrix.translate(x0, y0);

    this.createWorm(matrix, len);
    this.ctx.beginPath();
    this.ctx.strokeStyle = '#c10031';
    this.ctx.moveTo(this.px, this.py);
    this.ctx.lineTo(x0, y0);
    this.ctx.stroke();
    this.ctx.closePath();

    this.px = x0;
    this.py = y0;
  };

  createWorm = (mtx, len) => {
    let angle = Math.random() * (Math.PI / 6 - Math.PI / 64) + Math.PI / 64;

    if (Math.random() > 0.5) {
      angle *= -1;
    }

    let tmt = new Matrix2D();
    // tmt.context = this.ctx;
    tmt.scale(1.2, 1.2);
    //tmt.translate(12, 0);

    let w = 0.5;

    let worm = new Worm();

    worm.c1x = -w * mtx.c + mtx.tx;
    worm.p1x = -w * mtx.c + mtx.tx;

    worm.c1y = -w * mtx.d + mtx.ty;
    worm.p1y = -w * mtx.d + mtx.ty;

    worm.c2x = w * mtx.c + mtx.tx;
    worm.p2x = w * mtx.c + mtx.tx;

    worm.c2y = w * mtx.d + mtx.ty;
    worm.p2y = w * mtx.d + mtx.ty;

    worm.vmt = mtx;
    worm.tmt = tmt;

    worm.r = angle;
    worm.w = len / 20;
    worm.count = 0;

    // push new worm in worms state array
    this.setState({ worms: [...this.state.worms, worm ] });

    if (this.state.worms.length > this.MAX_NUM) {
      this.state.worms.shift();
    }

    // console.log(this.state.worms);
  };

  mouseDown = (e) => {
    e.preventDefault();
    this.canvas.width = this.width;
    this.setState({ worms: []});
  };

  mouseMove = (e) => {
    e.preventDefault();
    this.mouse.x = e.layerX;//- this.canvas.offsetLeft;
    
    // Chrome or chromium-based browser
    if (!!window.chrome){ // && (!!window.chrome.webstore || !!window.chrome.runtime)) {
      this.mouse.y = e.layerY - window.innerHeight*0.07;//- this.canvas.offsetTop;
    } else {
      this.mouse.y = e.layerY;//- this.canvas.offsetTop;
    }
  };

  magnitude = (x, y) => {
    return Math.sqrt(x * x + y * y);
  };

  render() {
    const canvasStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
    };
    
    const titleStyle = {
        position: 'fixed',
        color: '#FFF',
        fontFamily: "GoodBrush",
        fontSize: '6vh',
        lineHeight: '0px',
        textAlign: 'center',
        zIndex: 1,
    };
    
    return (
        <div style={canvasStyle}>
          <canvas ref={this.canvasRef} />
          <h1 className="unselectable" style={titleStyle}>{this.props.title}</h1>
        </div>
    );
  }
}

class Worm {
  constructor() {
    this.c1x = 0;
    this.c1y = 0;
    this.c2x = 0;
    this.c2y = 0;
    this.p1x = 0;
    this.p1y = 0;
    this.p2x = 0;
    this.p2y = 0;

    this.w = 0;
    this.r = 0;

    this.count = 0;
    this.vmt = 0;
    this.tmt = 0;
  }
}

export default MechanicalWeb;
