import React, { useState, useEffect, useRef } from 'react';
import Flicking, { MoveEvent, WillChangeEvent, ViewportSlot } from "@egjs/react-flicking";
import { Sync } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/pagination.css";

import { Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";

import "@egjs/react-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/react-flicking/dist/flicking-inline.css";

import "./experience.css";
import { ExperienceData } from "../../../../data/experience";
import { ExperienceEtudiant } from "../../../../data/experience-etu";

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faLocationDot } from '@fortawesome/free-solid-svg-icons'

function Experience() {

  const { t } = useTranslation();
  const _arrow = [new Arrow()];

  const flicking0 = useRef();
  const flicking1 = useRef();
  const flicking2 = useRef();
  const flicking3 = useRef();

  const [plugins, setPlugins] = useState([]);

  useEffect(() => {
    setPlugins([new Sync({
      type: "index",
      synchronizedFlickingOptions: [
        {
          flicking: flicking0.current,
          isSlidable: true
        },
        {
          flicking: flicking1.current,
          isClickable: true,
          isSlidable: false,
          activeClass: "active"
        },
        {
          flicking: flicking2.current,
          isSlidable: true
        },
        {
          flicking: flicking3.current,
          isClickable: true,
          isSlidable: false,
          activeClass: "active"
        }
      ]
    })]);
  }, []);

  // Mobile 
  // circularFallback="bound"

  return (
    <div>
      <div className="flicking-desktop">
        <Flicking ref={flicking0}
          plugins={_arrow}
          align="center"
          // bound={false}
          // circular={true}
          viewportTag="div"
          cameraTag="div"
          cameraClass=""
          renderOnSameKey={false}
          onMove={(e: MoveEvent) => { }}
          onWillChange={(e: WillChangeEvent) => { }}
          onMoveEnd={e => { }}>
          {ExperienceData.map((experience) => {
            return (
              <div className="card-panel" key={experience.role}>
              <Card className="exp">
                <div className="exp-logo">
                  <CardMedia
                    component="img"
                    // sx={ fit parent div and keep ratio aspects of the logo}
                    draggable="false"
                    image={experience.companyLogo}
                    alt={experience.company}
                  />
                </div>
                <CardContent style={{ padding: '10px' }}>
                  <Typography gutterBottom variant="h5" component="div">
                    <h2 className="role-name">{t(experience.role)}</h2>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <h3 className="company-name">{experience.company}</h3>
                    <h4 className="exp-details"><FontAwesomeIcon icon={faCalendar} /> {t(experience.date)} <br />( {t(experience.duration)} )</h4>
                    <br />
                    <h4 className="exp-location"><FontAwesomeIcon icon={faLocationDot} /> {t(experience.location)}</h4>
                    <p style={{ color: '#FFF' }}>{t(experience.description)}</p>
                  </Typography>
                </CardContent>
              </Card>
              </div>
            );
          })}
          <ViewportSlot>
            <span className="experience-arrow flicking-arrow-prev is-outside"></span>
            <span className="experience-arrow flicking-arrow-next is-outside"></span>
          </ViewportSlot>
        </Flicking>
        <Flicking ref={flicking1}
          bounce={30}
          align="center"
          circular={false}
          bound={false}
          plugins={plugins}>
            {ExperienceData.map((experience) => {
              return (
                <div key={experience.role} className="flicking-pagination-bullet"></div>
              )
            })}
        </Flicking>

        <h3 className="experience-notice">{t('experience-etu-prompt')}</h3>

        <Flicking
          align="center"
          bound={true}
          viewportTag="div"
          cameraTag="div"
          cameraClass=""
          renderOnSameKey={false}
          onMove={(e: MoveEvent) => { }}
          onWillChange={(e: WillChangeEvent) => { }}
          onMoveEnd={e => { }}>
          {ExperienceEtudiant.map((experience) => {
            return (
              <Card className="exp-stack" key={experience.image}>
                <CardMedia
                  component="img"
                  draggable="false"
                  image={experience.image}
                  alt={experience.alt}
                  title={experience.alt}
                  tooltip={experience.alt}
                />
              </Card>
            );
          })}
        </Flicking>
      </div>
      <div className="flicking-mobile">
        <Flicking
          ref={flicking2}
          align="center"
          circularFallback="bound"
          viewportTag="div"
          cameraTag="div"
          cameraClass=""
          renderOnSameKey={false}
          onMove={(e: MoveEvent) => { }}
          onWillChange={(e: WillChangeEvent) => { }}
          onMoveEnd={e => { }}>
          {ExperienceData.map((experience) => {
            return (
              <Card className="exp" key={experience.date}>
                <div className="exp-logo">
                  <CardMedia
                    component="img"
                    draggable="false"
                    // sx={ fit parent div and keep ratio aspects of the logo}
                    image={experience.companyLogo}
                    alt={experience.company}
                  />
                </div>
                <CardContent style={{ padding: '10px' }}>
                  <Typography gutterBottom variant="h5" component="div">
                    <h2 className="role-name">{t(experience.role)}</h2>
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <h3 className="company-name">{experience.company}</h3>
                    <h4 className="exp-details"><FontAwesomeIcon icon={faCalendar} /> {t(experience.date)} ( {t(experience.duration)} )</h4>
                    <h4 className="exp-location"><FontAwesomeIcon icon={faLocationDot} /> {t(experience.location)}</h4>
                    <p style={{ color: '#FFF' }}>{t(experience.description)}</p>
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </Flicking>
        <Flicking ref={flicking3}
          bounce={30}
          align="center"
          circular={false}
          bound={false}
          plugins={plugins}>
            {ExperienceData.map((experience) => {
              return (
                <div key={experience.role} className="flicking-pagination-bullet"></div>
              )
            })}
        </Flicking>

        
        <div>
          <h3 className="experience-notice">{t('experience-etu-prompt')}</h3>
        </div>

        <Flicking
          align="center"
          circularFallback="bound"
          viewportTag="div"
          cameraTag="div"
          cameraClass=""
          renderOnSameKey={false}
          onMove={(e: MoveEvent) => { }}
          onWillChange={(e: WillChangeEvent) => { }}
          onMoveEnd={e => { }}>
          {ExperienceEtudiant.map((experience) => {
            return (
              <Card className="exp-stack" key={experience.image}>
                <CardMedia
                  component="img"
                  draggable="false"
                  image={experience.image}
                  alt={experience.alt}
                  tooltip={experience.alt}
                />
              </Card>
            );
          })}
        </Flicking>
      </div>
    </div>
  );
}

export default Experience;