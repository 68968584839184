import React from "react";
import { Link } from "react-router-dom";
import './mobile.css';
import ToggleButton from '../../../../common/language-toggle';
import { withTranslation } from 'react-i18next';

function Mobile({ props, t, isOpen, setIsOpen }) {

  let styles = "mobile mobile-nav-closed";
  if (isOpen) styles = "mobile mobile-nav-opened";

  return (
    <div className={styles}>
      {/* <BurgerMenu isOpen={isOpen} /> */}
      <div className="mobile-option">
        <div className="initials">
          <Link to="/" state={{ fromResume: true }} replace={true} draggable="false">
            <h2 className="initials-k">K<span className="initials-a">a</span></h2>
          </Link>
        </div>
      </div>
      <div className="mobile-option">
        <a href="#intro" onClick={() => setIsOpen(!isOpen)}>
          <i className="fi-rr-edit-alt option-icon"></i>{"Intro".toUpperCase()}
        </a>
      </div>
      <div className="mobile-option">
        <a href="#education" onClick={() => setIsOpen(!isOpen)}>
          <i className="fi-rr-edit-alt option-icon"></i>{t('education').toUpperCase()}
        </a>
      </div>
      <div className="mobile-option">
        <a href="#projects" onClick={() => setIsOpen(!isOpen)}>
          <i className="fi-rr-briefcase option-icon"></i>{t('projects').toUpperCase()}
        </a>
      </div>
      <div className="mobile-option">
        <a href="#experience" onClick={() => setIsOpen(!isOpen)}>
          <i className="fi-rr-laptop option-icon"></i>{t('experience').toUpperCase()}
        </a>
      </div>
      <div className="mobile-option">
        <a href="#explore" onClick={() => setIsOpen(!isOpen)}>
          <i className="fi fi-rr-user option-icon"></i>{t('explore-title').toUpperCase()}
        </a>
      </div>
      <div className="mobile-option">
        <div className="language-toggle">
          <ToggleButton />
        </div>
      </div>
    </div>
  );
}

export default withTranslation()(Mobile);