import React from 'react';  
import "./burger.css";

function BurgerMenu(props) {

    let styles = "btn not-active";
    const { isOpen } = props;
    if (isOpen) styles = "btn active";


    return (
        <div className={styles}>
          <div className="menu-span"></div>
          <div className="menu-span"></div>
          <div className="menu-span"></div>
        </div>
    );
}

export default BurgerMenu;