import React, { useState } from "react";
import "./header.css";
import Mobile from "./mobile";
import Web from "./web/index";
import BurgerMenu from "../../../common/burger-menu/burger";
import AppBar from '@material-ui/core/AppBar';
// import Toolbar from '@material-ui/core/Toolbar';
// import CssBaseline from '@material-ui/core/CssBaseline';


function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      {/* <CssBaseline /> */}
      <AppBar className="navbar">
        <div className="header">
          <div className="menu">
            <div className="web-menu">
              <Web />
            </div>
          </div>
        </div>
      </AppBar>
      :
      {/* <div className="header"> */}
        <div className="menu">
          <div className="mobile-menu">
            <div onClick={() => setIsOpen(!isOpen)}>
              <BurgerMenu isOpen={isOpen} />
            </div>
            {isOpen && <Mobile isOpen={isOpen} setIsOpen={setIsOpen} />}
          </div>
        </div>
      {/* </div> */}

      {/* <Toolbar /> */}

    </div >
  );
}

export default Header;