import * as React from 'react';

import About from './body/about/index';
import EducationSection from './body/education/index';
import ProjectSection from './body/projects/index';
import ExperienceSection from './body/experience/index';
import ExploreSection from './body/explore/index';

import Header from './navbar/index';
import { SectionsContainer, Section, ScrollToTopOnMount } from './index';

import i18n from 'i18next';
import { withTranslation } from 'react-i18next';

import './homepage.css';

import fr_scroll_to_navigate from './../../../assets/icons/[FR]Scroll_navigation_frame.png';
import fr_navigate_top from './../../../assets/icons/[FR]Navigate_top_frame.png';
import en_scroll_to_navigate from './../../../assets/icons/[EN]Scroll_navigation_frame.png';
import en_navigate_top from './../../../assets/icons/[EN]Navigate_top_frame.png';
import arrow_up from './../../../assets/icons/scroll_arrow_up.png';
import arrow_down from './../../../assets/icons/scroll_arrow_down.png';


function navigation_helper_down () {
    const currentLng = i18n.language || window.navigator.language;
    if (currentLng === "fr") {
        return (<div><img draggable="false" className="arrow" src={arrow_down} alt=''/>
        <img draggable="false" className="top_frame" src={fr_scroll_to_navigate} alt=''/></div>);
    }  

    return (<div><img draggable="false" className="arrow" src={arrow_down} alt=''/>
    <img draggable="false" className="top_frame" src={en_scroll_to_navigate} alt=''/></div>);
}

function navigation_helper_up () {
    const currentLng = i18n.language || window.navigator.language;
    if (currentLng === "fr") {
        return (<div><img draggable="false" className="arrow" src={arrow_up} alt=''/>
        <img draggable="false" className="top_frame" src={fr_navigate_top} alt=''/></div>);
    }  

    return (<div><img draggable="false" className="arrow" src={arrow_up} alt=''/>
    <img draggable="false" className="top_frame" src={en_navigate_top} alt=''/></div>);
}

class Homepage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            current: 0,
            // enableArrows: true, 
            // width: window.innerWidth,
            // height: window.innerHeight,
        }
    }
    
    // handleResize = () => {
    //     if (this.state.width !== window.innerWidth || this.state.height !== window.innerHeight) {
    //         this.setState({ width: window.innerWidth, height: window.innerHeight })
    //         window.location.reload(false);
    //     }
    // }

    componentDidMount() {
        window.scrollTo(0, 0)
        // window.addEventListener('resize', this.handleResize);
    }
    
    // componentWillUnmount(){
    //     window.removeEventListener('resize', this.handleResize);
    // }

    onKeyDown = (e) => {
        // || e.key === "ArrowRight"
        if ((e.key === "Tab" || e.key === " " || e.key === "Enter" || e.key === "ArrowDown") && this.state.current < 4) {
            e.preventDefault();
            this.setState({ current: this.state.current + 1 });
        } 
        if ((e.key === "Tab" || e.key === " " || e.key === "Enter") && this.state.current === 4) {
            e.preventDefault();
            this.setState({ current: 0 });
        }
        // || e.key === "ArrowLeft"
        if ((e.key === "ArrowUp")) {
            return
            // this.setState({ current: (this.state.current-1) })

            // if (this.state.current <= 0)
            //     this.setState({ current: 0 })
            // if (this.state.current > 4)
            //     this.setState({ current: 4 })
        }
    }

    render() {

        // if (this.state.current === 4) {
        //     this.setState({ enableArrows: false });
        // }

        const options = {
            sectionClassName: 'section',
            anchors: ['intro', 'education', 'projects', 'experience', 'explore'],
            scrollBar: false,
            navigation: true,
            verticalAlign: false,
            // sectionPaddingTop: '5vh',
            // sectionPaddingBottom: '50px',
            arrowNavigation: false,
            scrollCallback: (states) => this.setState({current: states.activeSection})
        };

        const {current} = this.state

        return (
            <div className='custom-parallax' onKeyDown={this.onKeyDown}>
                <ScrollToTopOnMount />
                <Header />
                <SectionsContainer className="container" {...options} activeSection={current}>
                    {/* <Section className="custom-section glitch" verticalAlign="true" color="#140B34"><About /></Section> */}
                    <Section className="custom-section" verticalAlign="true" color="radial-gradient(circle, rgba(10,17,40,1) 0%, rgba(0,31,84,1) 60%, rgba(3,64,120,1) 90%, rgba(2,79,149,1) 100%)"><About /></Section>
                    <Section color="#FFF"><EducationSection color="linear-gradient(0deg, rgba(242,242,242,1) 0%, rgba(255,255,255,1) 100%)"></EducationSection></Section>
                    <Section color="#000"><ProjectSection color="radial-gradient(circle, rgba(0,0,0,1) 0%, rgba(22,34,29,1) 61%, rgba(13,30,23,1) 79%, rgba(11,34,20,1) 100%"></ProjectSection></Section>
                    <Section color="#222831"><ExperienceSection color="linear-gradient(0deg, rgba(34,46,64,1) 0%, rgba(34,43,57,1) 40%, rgba(34,40,49,1) 70%, rgba(33,36,41,1) 100%)"></ExperienceSection></Section>
                    <Section color="#052147"><ExploreSection color="linear-gradient(0deg, rgba(11,40,80,1) 0%, rgba(8,28,56,1) 100%)"></ExploreSection></Section>
                </SectionsContainer>

                <div className="btnGroup">
                    {current === 0? <button onClick={() => {this.setState({current: current + 1})}}>{navigation_helper_down()}</button> : <></> }
                    {current === 4? <button onClick={() => {this.setState({current: 0})}}>{navigation_helper_up()}</button> : <></> }
                    {/* {current === 4? <button onClick={this.increment}>Checkout the rest ↓</button> : <></> } */}
                </div>
                
            </div>
        )
    }
}

export default withTranslation()(Homepage);
