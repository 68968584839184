// Color palette source
// https://gist.github.com/robertpeteuil/bb2dc86f3b3e25d203664d61410bfa30

export const ProjectsData=[
    {
        "name": "basic-perceptrons",
        "topics": [
            "deep-learning",
            "pytorch",
            "mnist"
        ],
        "description": "This project offers a beginner's introduction to PyTorch, covering perceptrons and deep neural networks using the MNIST dataset. The goal is to recognize and classify handwritten digits from 0 to 9, providing a practical application for core machine learning concepts.",
        "html_url": "https://github.com/khaled-abdrabo/basic-perceptrons",
        "languages": [
            { id: 1, value: 100.0, realval: 9169, label: "Python", color: '#3572A5' },
        ]
    },
    {
        "name": "character-3danim",
        "topics": [
            "skeletal-animation",
            "3d-animation",
            "character-animation",
            "motion-graph"
        ],
        "description": "This project focuses on virtual 3D character animation, including creating a representative skeleton rig stored in BVH files, controlling the avatar with key press events, and transitioning between animations using a constructed motion graph.",
        "html_url": "https://github.com/khaled-abdrabo/character-3danim",
        "languages": [
            { id: 1, value: 100.0, realval: 70536, label: "C++", color: '#f34b7d' },
        ]
    },
    {
        "name": "COVID-Sim",
        "topics": [
            "simulation-environment",
            "netlogo",
            "multi-agent-system",
            "virus-propagation"
        ],
        "description": "This project simulates virus propagation in a population using NetLogo's 'Disease Solo'. It provides numerous experimental parameters, such as quarantine, mask wearing, and social distancing, to analyze their effectiveness in virus containment.",
        "html_url": "https://github.com/khaled-abdrabo/COVID-Sim",
        "languages": [
            { id: 1, value: 100.0, realval: 40024, label: "NetLogo", color: '#ff6375' },
        ]
    },
    {
        "name": "flow-free",
        "topics": [
            "java",
            "mvc",
            "javafx",
            "puzzle-game"
        ],
        "description": "This project features the development of the Flow Free game using Java and JavaFX, with an emphasis on the Model-View-Controller design pattern. The game offers an engaging puzzle experience, demonstrating the principles of MVC architecture in practice.",
        "html_url": "https://github.com/khaled-abdrabo/flow-free",
        "languages": [
            { id: 1, value: 96.9, realval: 45530, label: "Java", color: '#b07219' },
            { id: 2, value: 3.1, realval: 1472, label: "CSS", color: '#563d7c' },
        ]
    },
    {
        "name": "gym-deep-rl",
        "topics": [
            "deep-neural-networks",
            "deep-reinforcement-learning",
            "openai-gym",
            "pytorch",
            "convolutional-neural-networks",
            "openai-gym-environments"
        ],
        "description": "This project focuses on balancing a pole in OpenAI's Gym Cartpole environment using various deep learning techniques. It aims to apply and compare different approaches to solve the balancing challenge effectively.",
        "html_url": "https://github.com/khaled-abdrabo/gym-deep-rl",
        "languages": [
            { id: 1, value: 100.0, realval: 63780, label: "Python", color: '#3572A5' },
        ]
    },
    {
        "name": "IDEFIX",
        "topics": [
            "raspberry-pi",
            "opencv",
            "robotics",
            "tcp-protocol",
            "thymio"
        ],
        "description": "This project aimed to create a cat-and-mouse chase using Thymio robots, leveraging a Raspberry Pi, a camera module, and a set of robots. The challenge was to harness programming skills to orchestrate an engaging chase simulation.",
        "html_url": "https://github.com/khaled-abdrabo/IDEFIX",
        "languages": [
            { id: 1, value: 47.9, realval: 363954, label: "Python", color: '#3572A5' },
            { id: 2, value: 37.1, realval: 282256, label: "HTML", color: '#e44b23' },
            { id: 3, value: 11.5, realval: 87275, label: "Jupyter Notebook", color: '#a3da0b' },
            { id: 4, value: 2.1, realval: 16345, label: "CSS", color: '#563d7c' },
            { id: 5, value: 1.4, realval: 10874, label: "JavaScript", color: '#f1e05a' },
        ]
    },
    {
        "name": "projet-fk",
        "topics": [
            "opencv",
            "opengl",
            "cpp",
            "imgui",
            "blendshape"
        ],
        "description": "The project aimed to develop software for animating a 3D mesh with live-recorded facial expressions, incorporating morph target animation and interpolation techniques to achieve realistic and expressive facial animations.",
        "html_url": "https://github.com/khaled-abdrabo/projet-fk",
        "languages": [
            { id: 1, value: 88.5, realval: 240815, label: "C++", color: '#f34b7d' },
            { id: 2, value: 5.3, realval: 14362, label: "GLSL", color: '#5686a5' },
            { id: 3, value: 4.8, realval: 13051, label: "C", color: '#555555' },
            { id: 4, value: 1.4, realval: 3936, label: "CMake", color: '#DA3434' },
        ]
    },
    {
        "name": "qlearning",
        "topics": [
            "reinforcement-learning",
            "qlearning",
            "rl",
            "value-iteration",
            "gridworld-environment"
        ],
        "description": "This project focuses on implementing value iteration and Q-learning algorithms. We first test these agents in a Gridworld environment, then apply them to control a simulated robot (Crawler) and to the Pacman game. (Source: Berkeley's public projects and labs)",
        "html_url": "https://github.com/khaled-abdrabo/qlearning",
        "languages": [
            { id: 1, value: 87.5, realval: 216162, label: "Python", color: '#3572A5' },
            { id: 2, value: 11.9, realval: 29507, label: "HTML", color: '#e44b23' },
            { id: 3, value: 0.6, realval: 1423, label: "CSS", color: '#563d7c' },
        ]
    },
    {
        "name": "rick-morty-mvvm",
        "topics": [
            "android",
            "material-design",
            "recyclerview",
            "mvvm"
        ],
        "description": "This project involves developing a Rick and Morty-themed Android application using Kotlin. It implements the MVVM architecture and utilizes a public API to fetch and display data about characters from the Rick and Morty series.",
        "html_url": "https://github.com/khaled-abdrabo/rick-morty-mvvm",
        "languages": [
            { id: 1, value: 100.0, realval: 18838, label: "Kotlin", color: '#F18E33' },
        ]
    },
    {
        "name": "scrabble-solver",
        "topics": [
            "cplusplus",
            "gaddag",
            "premake4"
        ],
        "description": "This project aimed to create an automated Scrabble player designed to identify the highest-scoring moves. It leverages the GADDAG data structure to efficiently determine the best possible word placement for maximum points.",
        "html_url": "https://github.com/khaled-abdrabo/scrabble-solver",
        "languages": [
            { id: 1, value: 97.6, realval: 60831, label: "C++", color: '#f34b7d' },
            { id: 2, value: 2.3, realval: 1413, label: "Lua", color: '#000080' },
            { id: 3, value: 0.1, realval: 77, label: "Batchfile", color: '#C1F12E' },
        ]
    },
    {
        "name": "sentence2emotion",
        "topics": [
            "nlp",
            "pytorch",
            "seaborn",
            "torchtext"
        ],
        "description": "This project utilizes natural language processing and Recurrent Neural Networks (RNNs) to classify sentences into one of six predefined emotions. It focuses on accurately matching text to emotional categories based on advanced NLP techniques.",
        "html_url": "https://github.com/khaled-abdrabo/sentence2emotion",
        "languages": [
            { id: 1, value: 100.0, realval: 12976, label: "Python", color: '#3572A5' },
        ]
    },
    {
        "name": "slide-out",
        "topics": [
            "cplusplus",
            "sdl2",
            "box2d"
        ],
        "description": "This project involves developing a game similar to Tiny Wings, featuring dynamic stylized slope generation and engaging gameplay. It utilizes the Box2D 2D physics game engine for realistic in-game physics management and SDL2 for handling mutlimedia and user events.",
        "html_url": "https://github.com/khaled-abdrabo/slide-out",
        "languages": [
            { id: 1, value: 99.0, realval: 691674, label: "C++", color: '#f34b7d' },
            { id: 3, value: 0.6, realval: 4309, label: "C", color: '#555555' },
            { id: 3, value: 0.4, realval: 2882, label: "Makefile", color: '#ededed' },
        ]
    },
    {
        "name": "smart-plant-pot",
        "topics": [
            "nodejs",
            "iot",
            "arduino",
            "reactjs"
        ],
        "description": "This project aims to use a hygrometer to measure the humidity level of a flower pot and notify users about their plant’s watering needs. It also includes features for identifying the plant and storing its humidity data in the app for ongoing monitoring.",
        "html_url": "https://github.com/khaled-abdrabo/smart-plant-pot",
        "languages": [
            { id: 1, value: 74.1, realval: 36299, label: "JavaScript", color: '#f1e05a' },
            { id: 2, value: 11.2, realval: 5506, label: "C++", color: '#f34b7d' },
            { id: 3, value: 7.1, realval: 3492, label: "Python", color: '#3572A5' },
            { id: 4, value: 4.1, realval: 2025, label: "CSS", color: '#563d7c' },
            { id: 5, value: 3.5, realval: 1721, label: "HTML", color: '#e44b23' },
        ]
    },
    {
        "name": "style-transfer",
        "topics": [
            "pytorch",
            "vgg",
            "convolutional-neural-networks",
            "torchvision"
        ],
        "description": "The goal of this project is to produce artistic images by merging the content from one image with the style of another. Using a pretrained VGG19 model, it minimizes both content and style loss achieving visually compelling results.",
        "html_url": "https://github.com/khaled-abdrabo/style-transfer",
        "languages": [
            { id: 1, value: 100.0, realval: 6079, label: "Python", color: '#3572A5' },
        ]
    },
    {
        "name": "sudoku-solver",
        "topics": [
            "constraint-programming",
            "ortools",
            "cp-sat"
        ],
        "description": "This project offers a robust and flexible solution for solving Sudoku puzzles of different sizes (9x9, 16x16, and 36x36). It utilizes constraint programming techniques, specifically OR-tools' CP-SAT models, for effective puzzle solving.",
        "html_url": "https://github.com/khaled-abdrabo/sudoku-solver",
        "languages": [
            { id: 1, value: 100.0, realval: 9118, label: "Python", color: '#3572A5' },
        ]
    },
];