export const ExperienceEtudiant = [
    {
        image: require('../../assets/experiences/upwork.png'),
        alt: "UpWork" 
    },
    {
        image: require('../../assets/experiences/intermarche.png'),
        alt: "Intermarché" 
    },
    {
        image: require('../../assets/experiences/papajohns.png'),
        alt: "Papa John's" 
    },
    {
        image: require('../../assets/experiences/carrefour.png'),
        alt: "Carrefour" 
    },
    {
        image: require('../../assets/experiences/eurexpo.png'),
        alt: "Eurexpo" 
    },
    {
        image: require('../../assets/experiences/jobypepper.png'),
        alt: "JobyPepper" 
    },
]