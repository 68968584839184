import React from "react";
import { EducationData } from "../../../../data/education";
import { withTranslation } from 'react-i18next';

import Flicking, { MoveEvent, WillChangeEvent, ViewportSlot } from "@egjs/react-flicking";
import { Arrow } from "@egjs/flicking-plugins";
import "@egjs/flicking-plugins/dist/arrow.css";

import "@egjs/react-flicking/dist/flicking.css";
// Or, if you have to support IE9
import "@egjs/react-flicking/dist/flicking-inline.css";
import './education.css';


function Education({ t }) {
  const _arrow = [new Arrow()];

  // const handleKeyUp = (event) => {
  //   // console.log('A key was pressed', event.keyCode);
  //   document.getElementById(".flicking-viewport").next()
  // };

  // useEffect(() => {
  //   window.addEventListener('keyup', handleKeyUp);

  //   // cleanup this component
  //   return () => {
  //     window.removeEventListener('keyup', handleKeyUp);
  //   };
  // }, []);

  return (
    <div className="cards-container">
      <div className="cards">
        <Flicking
          id="education-flicking"
          plugins={_arrow}
          align="center"
          renderOnSameKey={false}
          onMove={(e: MoveEvent) => { }}
          onWillChange={(e: WillChangeEvent) => { }}
          onMoveEnd={e => { }}
        >
          {EducationData.map((education) => {
            return (
              <div className="card" key={education.initials}>
                <div className="paper-card">
                    {education._initials ?
                      <div>
                        <br />
                        <h2 className="card_initials">{t(education.initials)}</h2>
                        <h2 className="card_title">{t(education.title)}</h2>
                        <h1 className="card_location">{t(education.establishment)}</h1>
                        <a href={education.url_formation} draggable="false" target="_blank" rel="noopener noreferrer"><p className="card_link">{t('url-formation')}</p></a>
                        <h2 className="card_initials"><br />{t(education._initials)}</h2>
                        <h2 className="card_title">{t(education._title)}</h2>
                        {/* <h2 className="card_year">— {education._year} —</h2> */}
                        <h1 className="card_location">{t(education._establishment)}</h1>
                        <a href={education._url_formation} draggable="false" target="_blank" rel="noopener noreferrer"><p className="card_link">{t('url-formation')}</p></a>
                      </div>
                      :
                      <div>
                        <h2 className="card_initials">{t(education.initials)}</h2>
                        <h2 className="card_title">{t(education.title)}</h2>
                        {/* <h2 className="card_year">— {education.year} —</h2> */}
                        {education.establishment === "ucbl" ?
                          <h1 className="card_location">{t(education.establishment)} * </h1>
                          :
                          <h1 className="card_location">{t(education.establishment)}</h1>
                        }
                        <div className="card_image card_image_h"><img src={education.imgH} alt={t(education.title)} /></div>
                        <div className="card_image card_image_v"><img src={education.imgV} alt={t(education.title)} /></div>
                        <a href={education.url_formation} draggable="false" target="_blank" rel="noopener noreferrer"><p className="card_link">{t('url-formation')}</p></a>
                      </div>
                    }
                  {/* <img src={require('../../../../../assets/education/ripped-paper-texture.jpg')} alt="" />   */}
                </div>
              </div>
            )
          })}
          <ViewportSlot>
            <span className="flicking-arrow-prev is-circle"></span>
            <span className="flicking-arrow-next is-circle"></span>
          </ViewportSlot>
        </Flicking>

      </div>
    </div>
  );
}

export default withTranslation()(Education);

