export const ExperienceData = [
    {
        role: "ai-engineer",
        company: "IVèS" ,
        date: "ives-duration",
        duration: "6-months",
        location: "monbonnot-address",
        companyLogo: require("../../assets/experiences/ives.png"),
        // description: "ives-description",
    },
    {
        role: "full-stack-dev",
        company: "Ezus Lyon" ,
        date: "ezus-duration",
        duration: "6-months",
        location: "villeurbanne-address",
        companyLogo: require("../../assets/experiences/ezuslyon.png"),
        // description: "ezus-description",
    },
    {
        role: "android-dev",
        company: "MonStock" ,
        date: "monstock-duration",
        duration: "3-months",
        location: "bezannes-address",
        companyLogo: require("../../assets/experiences/monstock.png"),
        // description: "monstock-description"
    },
    {
        role: "software-dev",
        company: "ARCA" ,
        date: "arca-duration",
        duration: "1-month",
        location: "alexandria-address",
        companyLogo: require("../../assets/experiences/arca.png"),
        // description: "arca-description"
    },
]