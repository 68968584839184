import React, { Component, memo } from 'react';

import './demos.css';

class DemosSection extends Component {
  render() {
    return (
      <div>
        <h2>Demos Section</h2>
        <p>Under construction, come back later</p>
      </div>
    );
  }
}

export default memo(DemosSection);