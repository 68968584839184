import React, { Fragment } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import useVisitTracker from "./components/common/cluster-maps/visitTracker";
import { ACK, AIML, Art, Blog, Boo, Contact, Demos, Electro, Home, Tree, VFX } 
  from '../src/components/pages';
import { Helmet } from "react-helmet";

import './App.css';

function App() {
  // Track website visitor clusters and heatmaps 
  useVisitTracker();

  return (
    <>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-4B6PTLZ54P"></script>
      </Helmet>

      <div className="wrapper">
        <BrowserRouter>
          <Fragment>
            <Routes>
              <Route path="/ack" Component={ACK} />
              <Route path="/ai-ml" Component={AIML} />
              <Route path="/art" Component={Art} />
              <Route path="/boo" Component={Boo} />
              <Route path="/contact" Component={Contact}  /> 
              <Route path="/demos" Component={Demos} />
              <Route path="/electro" Component={Electro} />
              <Route path="/words-n-ideas" Component={Blog} />
              <Route path="/vfx" Component={VFX} />
              <Route path="/resume" Component={Home} /> 
              <Route path="/homepage" Component={Tree} /> 
              <Route path="/" Component={Tree} />
            </Routes>
          </Fragment>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
