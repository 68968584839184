import React, { Component, memo } from 'react';
import { Box, Stack, Paper } from '@mui/material';
import ParticlesCanvas from './particles-canvas';
import Header from './../resume/navbar/index';

import { AIMLStackData } from "./../../data/ai-ml-stack";

import './ai.css';

class AISection extends Component {
  render() {
    return (
      <div>
        <Header />
        <ParticlesCanvas />
        <div className="ai-section">
          <h1 className="ai-section-title">AI - ML</h1>
          <div className="ai-section-content">
            {AIMLStackData.map((item) => {
              return (
              <div className="ai-section-card">
                <h2 className="ai-card-title">{(item.name).replaceAll('-', ' ')}</h2>
                <p className="ai-card-description"><b>Completion Date</b></p>
                <p className="ai-card-description">{item.description}</p>
                {item.topics ? 
                <>
                  <div className="ai-card-tags">
                    <Box>
                      <Stack 
                        direction="row" 
                        spacing={2} 
                        useFlexGap={true}
                        sx={{
                          justifyContent: "left",
                          alignItems: "left",
                          flexWrap: 'wrap',
                        }}>
                          <p className="ai-card-topics-title">Topics</p>
                          {item.topics.map((topic) => {
                            return (<Paper className="ai-card-tag">{topic}</Paper>)
                          })}
                        </Stack>
                    </Box>
                  </div>
                </> 
                : 
                <></>}
                
              </div>)
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default memo(AISection);