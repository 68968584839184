import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import GlitchAnimation from './header/glitch';
import Education from './content';

import '../parallax-container.css';

class EducationSection extends Component {

    render () {
        return (
        <div className="custom-parallax-container" style={{ background: this.props.color }}>
            <div className='custom-body'>
                <div className="section-parallax section-1"></div>
                <div className="section-parallax section-2">
                  <GlitchAnimation title={this.props.t("education").toUpperCase()} />
                </div>
                <div className="section-parallax section-3 slimmer-section"><Education /></div>
            </div>
        </div>
        );
    }

};

export default withTranslation()(EducationSection); 