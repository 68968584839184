import React from "react";

import { Box, Paper, Stack } from '@mui/material';
import MultiProgress from "react-multi-progress";
import { PieChart } from '@mui/x-charts/PieChart';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import "./project-card.css";


function toTitleCase(str) {
  return str.toLowerCase().split(' ').map(function (word) {
    return (word.charAt(0).toUpperCase() + word.slice(1));
  }).join(' ');
}

function ProjectCard({ project, projectOrder, backgroundImg }) {
  const { t } = useTranslation();

  return (
    <CardContent 
      // sx={{
        // backgroundImage:`url(${backgroundImg})`,
        // backgroundRepeat: "no-repeat",
        // backgroundSize: "cover",
        // height: "385px",
      // }}
      >
      
      <div className="project-table-container">

      <div className="project-card-section-1">
        <Typography variant="h3" component="div">
          {/* <img src={require('../../../../../assets/projects/icons/' + project.name.toLowerCase() + '.png')}  alt="" /> */}
          <div className="project-title">
            <div className="project-order">—&nbsp;{projectOrder}&nbsp;—</div>
            {toTitleCase(project.name.replaceAll('-', ' '))}
          </div>
        </Typography>
      </div>
      
      <div className="project-content-desktop">
        {project.topics.length ?
          <Box>
            <Stack 
              direction="row" 
              spacing={1} 
              useFlexGap={true}
              sx={{
                justifyContent: "center",
                alignItems: "center",
                flexWrap: 'wrap',
              }}>
              {project.topics.map((topic) => {
                  return <Paper className="tag" key={topic}>{topic}</Paper>
              })}
            </Stack>
          </Box>
          : <></>}
      </div>

      <div className="project-card-section-2">
        <div className="project-content-mobile">
          {project.description ?
            <div className="project-desc">{project.description}</div>
            : <></>
          }
        </div>
      </div>

      <div className="project-card-section-3">
        <div className="project-content-mobile">
          {project.topics.length ?
            <Box>
              <Stack 
                direction="row" 
                spacing={1} 
                useFlexGap={true}
                sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: 'wrap',
                }}>
                {project.topics.map((topic) => {
                    return <Paper className="tag" key={topic}>{topic}</Paper>
                })}
              </Stack>
            </Box>
            : <></>}
        </div>
      </div>

      <div className="project-content-desktop">
        {project.description ?
          <div className="project-desc">{project.description}</div>
          : <></>
        }
      </div>

      <div className="project-card-section-4">
        <div>
          <p className="project-languages">{t('programming_languages')}</p>
        </div>

        <div className="chart-desktop">
          <PieChart
            sx={{ "& .MuiChartsLegend-series text": { fontSize: "0.8vw !important", fill: 'white !important',}, 
                  "& .MuiChartsLegend-series rect": { ry: 3, }, }} //10
            margin={{ top: 20, bottom: 40, left: 20, right: 20 }}
            slotProps={{
              // Custom legend positioning
              legend: {
                hidden: false,
                direction: 'row',
                position: { vertical: 'bottom', horizontal: 'middle' },
                padding: 0,
                itemMarkWidth: 20,
                itemMarkHeight: 7,
                markGap: 5,
                itemGap: 10,
              },
              // Custom loading message
              loadingOverlay: { message: 'Data should be available soon.' },
              // Custom message for empty chart
              noDataOverlay: { message: 'Select some data to display.' },
            }}
            series={[
              {
                data: project.languages.map((d) => ({ label: d.label, color: d.color, value: d.value })),
                valueFormatter: (v) => { return `${v.value}%`; },
                highlightScope: { fade: 'global', highlight: 'item' },
                faded: { innerRadius: 20, additionalRadius: -30, color: 'gray' },
              },
            ]}
            height={150}
          />
        </div>
        <div className="chart-mobile">
          <MultiProgress
            transitionTime={1.2}
            elements={project.languages}
            height={12}
            backgroundColor="black"
            roundLastElement={false}
          />

          <div className="center-chart-legend-mobile">
            <table>
              <tbody>
                {project.languages.map((d) => {
                  return (
                    <tr key={d.realval}>
                      <td className="chart-mobile-data"><svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="15" height="10"><circle cx="5" cy="5" r="5" fill={d.color} /></svg></td>
                      <td className="chart-mobile-data">{d.label} ({d.value}%)</td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      
      <div className="project-card-section-5">
        <a href={project.html_url}
            draggable="false" 
            className="project-action-btn"
            target="_blank"
            rel="noopener noreferrer" >
            {t('goto_github')}&nbsp;&nbsp;&nbsp;
            <FontAwesomeIcon icon={faGithub} />
          </a>
        </div>
      </div>

    </CardContent>
  );
}

export default ProjectCard;