import React, { Component } from 'react';

class MatrixRain extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.fontSize = 7;
    this.columns = window.innerWidth / this.fontSize;
    this.drops = [];

    for (let i = 0; i < this.columns; i++) {
      this.drops[i] = 1;
    }
  }

  resize = () => {
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight * 0.1;
    this.forceUpdate()
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.canvas = this.canvasRef.current;
    this.ctx = this.canvas.getContext('2d');
    this.canvas.width = window.innerWidth;
    this.canvas.height = window.innerHeight * 0.1;
    this.drawInterval = setInterval(this.draw, 70);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    clearInterval(this.drawInterval);
  }

  draw = () => {
    const { ctx, canvas, fontSize, drops } = this;

    ctx.fillStyle = 'rgba(0, 0, 0, .1)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    for (let i = 0; i < drops.length; i++) {
      const text = this.letters[Math.floor(Math.random() * this.letters.length)];
      ctx.fillStyle = '#0f0';
      ctx.fillText(text, i * fontSize, drops[i] * fontSize);
      drops[i]++;

      if (drops[i] * fontSize > canvas.height && Math.random() > 0.95) {
        drops[i] = 0;
      }
    }
  };

  letters = 'ABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZABCDEFGHIJKLMNOPQRSTUVXYZ'.split('');

  render() {
    const canvasStyle = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    };
  
    const titleStyle = {
      // lineHeight: '0px',
      position: 'fixed',
      color: '#FFF',
      fontFamily: "Blade",
      fontSize: '3.5vmax',
      textAlign: 'center',
      zIndex: 1,
    };
  
    return (
      <div style={canvasStyle}>
        <canvas ref={this.canvasRef} />
        <h1 style={titleStyle}>{this.props.title}</h1>
      </div>
    );
  }
}

export default MatrixRain;
