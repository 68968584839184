import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MechanicalWeb from './header/mechanical-web';
import Experience from './content';

import '../parallax-container.css';
import './header/worm.css';

class ExperienceSection extends Component {
    
    render () {
        return (
        <div className="custom-parallax-container">
            <div className='custom-body'>
                <div className="section-parallax section-1" style={{ backgroundColor: this.props.color }}></div>
                <div className="section-parallax section-2 worm">
                  <MechanicalWeb title={this.props.t("experience").toUpperCase()} />
                </div>
                <div className="section-parallax section-3" style={{ background: this.props.color }}><Experience /></div>
            </div>
        </div>
        );
    }

};

export default withTranslation()(ExperienceSection); 