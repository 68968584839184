import React, { Component, memo } from 'react';
import PeepAnimation from './crowd-sim/crowd-sim';

class ACKSection extends Component {
  render() {
    return (
      <div>
        <PeepAnimation />
      </div>
    );
  }
}

export default memo(ACKSection);