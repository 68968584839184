class Peep {
    constructor({
      image,
      rect,
    }) {
      this.image = image
      this.setRect(rect)
      
      this.x = 0
      this.y = 0
      this.anchorY = 0
      this.scaleX = 1
      this.walk = null
    }
    
    setRect(rect) {
      this.rect = rect
      this.width = rect[2]
      this.height = rect[3]
      
      this.drawArgs = [
        this.image,
        ...rect,
        0, 0, this.width, this.height
      ]  
    }
    
    render(ctx) {
      // Scale peep images to device aspect ratio
      let windowWidth = window.innerWidth;
      let windowHeight = window.innerHeight;
      let aspectRatio = (windowWidth / windowHeight) * 0.4;
      // Portrait mode
      if (windowHeight < windowWidth) {
        if (windowWidth <= 1024) {
          aspectRatio = (windowWidth / windowHeight) * 0.5;
        } else if (windowHeight <= 720) {
          aspectRatio = (windowWidth / windowHeight) * 0.1;
        }
      // Landscape mode
      } else {
        if (windowHeight <= 720) {
          aspectRatio = (windowWidth / windowHeight) * 0.5;
        } else if (windowHeight <= 720) {
          aspectRatio = (windowWidth / windowHeight) * 0.1;
        }
      }
      
      ctx.save()
      ctx.translate(this.x, this.y)
      ctx.scale((aspectRatio/this.scaleX), (aspectRatio/1))
      ctx.drawImage(...this.drawArgs)
      ctx.restore()
    }
}

export default Peep;