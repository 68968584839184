import './about.css';

import React from 'react';
import SocialContact from '../../../../common/social-contact/index';
// import HologramAnimation from './animation/hologram';
// import VolumetricLightComponent from './animation/hologram_v2';
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { useGlitch } from 'react-powerglitch'

import cven from '../../../../../assets/docs/resume_web.pdf';
import cvfr from '../../../../../assets/docs/resume_web.pdf';


function About({ t }) {

  // Default en
  let filename = cven;
  if (i18n.language === 'fr')
    filename = cvfr;

  const glitch = useGlitch({ 
    shake: false,
    hideOverflow: false,
  });

  return (
    <>
      <div className='about'>
        <div className="hero-container">
          <div className="environment"></div>
          <h2 className="important-info brush hero glitch layers" ref={glitch.ref} data-text={"Hello World"}>
            <div className="custom-span">Hello</div>
            <div className="custom-span">World</div>
          </h2>
        </div>
        <div className="plain-text">
          <a href={filename} target="_blank" rel="noreferrer" >{t('plain-text')} →</a>
        </div>
        <SocialContact textColor={""}/>
        {/* <div className="about-photo">
              <img 
                src={require("../../../../../assets/coding.png")}
                alt="Coding illustration"
                className="picture"
              />
          </div> */}
        {/* <HologramAnimation /> */}
      </div>
    </>
  );
}

export default withTranslation()(About);