class Matrix2D {
  constructor() {
    this.a = 1;
    this.b = 0;
    this.c = 0;
    this.d = 1;
    this.tx = 0;
    this.ty = 0;
  }

  scale(sx, sy) {
    this.a *= sx;
    this.b *= sx;
    this.c *= sy;
    this.d *= sy;
  }

  rotate(angle) {
    const cos = Math.cos(angle);
    const sin = Math.sin(angle);
    const { a, b, c, d } = this;

    this.a = a * cos - b * sin;
    this.b = a * sin + b * cos;
    this.c = c * cos - d * sin;
    this.d = c * sin + d * cos;
  }

  translate(tx, ty) {
    this.tx += tx;
    this.ty += ty;
  }

  prependMatrix(matrix) {
    const { a, b, c, d, tx, ty } = this;
    const { a: ma, b: mb, c: mc, d: md, tx: mtx, ty: mty } = matrix;

    this.a = ma * a + mc * b;
    this.b = mb * a + md * b;
    this.c = ma * c + mc * d;
    this.d = mb * c + md * d;
    this.tx = ma * tx + mc * ty + mtx;
    this.ty = mb * tx + md * ty + mty;
  }
}

export default Matrix2D;
