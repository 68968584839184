import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MySvgComponent from './header/svg-component';
import ContactContent from './content';

import '../parallax-container.css';

function setBackgroundColor(color) {
    if (color === null || color === undefined) {
        return "#081C38";
    }
    return color;
}

class Contact extends Component {
    
    render () {
        return (
            <>
            <div className="custom-parallax-container">
                <div className='custom-body'>
                    <div className="section-parallax section-1" style={{ backgroundColor: setBackgroundColor() }}></div>
                    <div className="section-parallax section-2">
                    <MySvgComponent title={this.props.t("contact-title").toUpperCase()} />
                    </div>
                    <div className="section-parallax section-3" style={{ paddingTop: '7vh', backgroundColor: setBackgroundColor() }}><ContactContent /></div>
                </div>
            </div>
            </>
        );
    }

};

export default withTranslation()(Contact); 
