export const EducationData=[
    {
        imgH: require("../../assets/education/frame1_horizontal.png"),
        imgV: require("../../assets/education/frame1_vertical.png"),
        initials: "m2",
        title: "ai",        
        establishment: "ucbl",
        url_formation: "http://master-info.univ-lyon1.fr/IA/",
        year: 2022,
    },
    {
        initials: "m1",
        title:"master_info",
        establishment: "* UCBL",
        url_formation: "http://master-info.univ-lyon1.fr/M1/",
        year: 2020,

        _initials: "licence",
        _title: "licence_info",
        _subtitle: "Parcours Informatique",        
        _establishment: "* UCBL",
        _url_formation: "http://licence-info.univ-lyon1.fr/",
        _year: 2017,
    },
    {
        imgH: require("../../assets/education/frame2_horizontal.png"),
        imgV: require("../../assets/education/frame2_vertical.png"),
        initials: "BAC",
        title: "bac",
        establishment: "Collège Saint-Marc",
        url_formation: "https://fr.wikipedia.org/wiki/Coll%C3%A8ge_Saint-Marc_d%27Alexandrie",
        year: 2017,
    },
];